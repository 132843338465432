
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  setup() {
    const state = reactive({
      visible: false,
    });

    const handleClick = () => {
      state.visible = !state.visible;
    };
    return {
      state,
      handleClick,
    };
  },
});
